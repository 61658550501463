<template>
  <h3>
    <button class="btn btn-primary d-block d-md-none" id="toggleAccountSettingMenu">
      <i class="fa fa-bars"></i>
    </button>
    {{ $props.title }} - This page is building
  </h3>
</template>

<script>
export default {
  name: 'SettingTitle',
  props: ['title'],
};
</script>

<style scoped></style>
