<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Account Setting</h1></div>
    </div>
    <div class="row">
      <div class="col-md-3 d-none d-md-block" id="menuWrapper">
        <AccountSettingMenu activeMenu="transaction-history" />
      </div>
      <div class="col-md-9">
        <SettingTitle title="Transaction History" />
        <div class="table-responsive">
          <table class="table table-striped table-bordered" id="payout_methods">
            <thead>
              <tr class="text-truncate bg-light text-secondary">
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Paid Out</th>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              <tr id="add_payout_method_section">
                <td colspan="5">This page is building</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingMenu from './Menu';
import SettingTitle from './SettingTitle';
export default {
  name: 'AccountSetting',
  components: { AccountSettingMenu, SettingTitle },
};
</script>

<style scoped></style>
