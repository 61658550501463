<template>
  <ul class="nav nav-pills flex-column text-start" id="accountSettingMenu">
    <li class="nav-item">
      <a
        href="/account-setting/notification"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'notification' }"
      >
        <i class="fa fa-flag"></i>Notification
      </a>
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/payment-method"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'payment-method' }"
        ><i class="fa fa-credit-card"></i>Payment method</a
      >
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/payout-method"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'payout-method' }"
        ><i class="fa fa-eye-dropper"></i>Payout method</a
      >
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/transaction-history"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'transaction-history' }"
        ><i class="fa fa-history"></i>Transaction History</a
      >
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/privacy"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'privacy' }"
        ><i class="fa fa-language"></i>Privacy</a
      >
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/security"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'security' }"
        ><i class="fa fa-lock"></i>Security</a
      >
    </li>
    <li class="nav-item">
      <a
        href="/account-setting/cancel-account"
        :class="{ 'nav-link': true, active: $props.activeMenu == 'cancel-account' }"
        ><i class="fa fa-times"></i>Cancel Account</a
      >
    </li>
  </ul>
</template>

<script>
var isClickedAccountSettingMenu = false;
window.addEventListener('click', function (e) {
  if (document.getElementById('toggleAccountSettingMenu').contains(e.target)) {
    // Clicked in box
    console.log('Clicked in box');
    document.getElementById('menuWrapper').classList.toggle('d-none');
    isClickedAccountSettingMenu = true;
  } else {
    // Clicked outside the box
    if (isClickedAccountSettingMenu) {
      console.log('Clicked outside box');
      document.getElementById('menuWrapper').classList.toggle('d-none');
      isClickedAccountSettingMenu = false;
    }
  }
});
export default {
  name: 'AccountSettingMenu',
  props: ['activeMenu'],
};
</script>

<style scoped></style>
